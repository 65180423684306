<template>
  <div class="p-4 bg-white rounded-lg">
    <ReportCharts :urls="urls" mandatory-filter />
  </div>
</template>

<script>
import ReportCharts from "@/own/components/reports/ReportCharts";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "FulfilledvsDelivered",
  components: { ReportCharts },
  data: () => ({
    urls: {
      data_url: "/reports/reportFulfilledVsDelivered",
      show_url: "/reports/reportFulfilledVsDelivered/show",
    },
  }),

  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
